import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuRounded } from "@mui/icons-material";
import styled from "styled-components";

// Define routes
export const routes = [
  { title: "Home", path: "/" },
  { title: "Projects", path: "/projects" },
  { title: "Blogs", path: "https://brittytino.blogspot.com/" },
];

// Styled Components
const Nav = styled.nav`
  background-color: black;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 50;
  color: white;
`;

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`;

const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  text-decoration: none;
  color: inherit;

  .logo-text {
    display: inline-block;
    position: relative;

    span {
      display: inline-block;
      transition: color 0.3s ease, transform 0.3s ease;
    }
    
    span:hover {
      color: #ec4899; /* Tailwind pink-500 */
      transform: translateY(-2px);
    }
  }
`;

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  gap: 32px;
  list-style: none;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.text_primary || "#ffffff"};
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #ec4899; /* Tailwind pink-500 */
  }
`;

const MobileIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    color: ${({ theme }) => theme.text_primary || "#ffffff"};
  }
`;

const MobileMenu = styled.ul`
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  background: ${({ theme }) => theme.bg || "#1f2937"};
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  padding: 12px 24px;
  list-style: none;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(-100%)")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1000")};
  transition: all 0.3s ease;
  margin: 0;
  background-color: black;

`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? "" : "hidden";
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <Nav>
      <NavbarContainer>
        {/* Logo */}
        <NavLogo to="/">
          <div className="logo-text">
            {"Tino Britty".split("").map((letter, index) => (
              <span key={index}>{letter}</span>
            ))}
          </div>
        </NavLogo>

        {/* Desktop Navigation Items */}
        <NavItems>
          {routes.map((item, index) => (
            <li key={index}>
              <NavLink to={item.path}>{item.title}</NavLink>
            </li>
          ))}
        </NavItems>

        {/* Mobile Menu Icon */}
        <MobileIcon onClick={toggleMenu}>
          <MenuRounded />
        </MobileIcon>

        {/* Mobile Menu */}
        <MobileMenu isOpen={isMenuOpen}>
          {routes.map((item, index) => (
            <li key={index}>
              <NavLink onClick={toggleMenu} to={item.path}>
                {item.title}
              </NavLink>
            </li>
          ))}
        </MobileMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
