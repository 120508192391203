import styled, { ThemeProvider } from "styled-components";
import { darkTheme } from "./utils/Themes";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import Route and Routes
import Hero from "./components/sections/Hero";
import Skills from "./components/sections/Skills";
import { AnimatePresence } from "framer-motion";
import Education from "./components/sections/Education";
import Experience from "./components/sections/Experience";
import Projects from "./components/sections/Projects";
import Projects1 from "./components/sections/Projects1";
import Contact from "./components/sections/Contact";
import Footer from "./components/sections/Footer";
import ProjectDetails from "./components/Dialog/ProjectDetails";
import { useState } from "react";
import LinkedInWidget from "./components/sections/LinkedInWidget";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: black;
`;

const Wrapper = styled.div`
  padding-bottom: 100px;
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <AnimatePresence>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Navbar />
                  <Body>
                    <Hero />
                    {/*<Skills />*/ }
                    <Projects1 openModal={openModal} setOpenModal={setOpenModal} />
                    <LinkedInWidget />
                    <Contact />
                    <Footer />
                  </Body>
                </>
              }
            />
            <Route
              path="/experience"
              element={
                <>
                  <Navbar />
                  <Body>
                  <Experience />
                    <Footer />
                  </Body>
                </>
              }
            />
            <Route
              path="/education"
              element={
                <>
                  <Navbar />
                  <Body>
                  <Education />
                    <Footer />
                  </Body>
                </>
              }
            />
            <Route
              path="/projects"
              element={
                <>
                  <Navbar />
                  <Body>
                  <Projects openModal={openModal} setOpenModal={setOpenModal} />
                    <Footer />
                  </Body>
                </>
              }
            />
            
          </Routes>
          {openModal.state && (
            <ProjectDetails
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          )}
        </AnimatePresence>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
