import React, { useState } from "react";
import styled from "styled-components";
import ProjectCard from "../cards/ProjectCard"; // Ensure this path is correct
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import pic1 from "../pics/Sheapphome.png";
import pic5 from "../pics/ordusion.png";
import pic3 from "../pics/cybersentinalhome.png";
// Styled Components
const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 0 16px;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
`;

const StyledButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: start;
  padding: 12px 24px;
  font-weight: 600;
  color: #4f46e5; /* Indigo-600 */
  background-color: #f9fafb; /* Gray-50 */
  border-radius: 6px;
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    padding-left: 40px;
    padding-right: 20px;
    background-color: #4f46e5; /* Indigo-600 */
    color: #ffffff;
  }

  span {
    position: absolute;
    transition: all 0.2s ease-in-out;
  }

  .svg-left {
    left: 10px;
    transform: translateX(-30px);
  }

  .svg-right {
    right: 10px;
    transform: translateX(30px);
  }

  &:hover .svg-left {
    transform: translateX(0);
  }

  &:hover .svg-right {
    transform: translateX(0);
  }
`;

// Main Component
const Projects1 = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState("all");

  const handleToggle = (category) => {
    setToggle(category);
  };

  // Use the projects1 array defined below
  const projects = projects1;

  return (
    <Container id="Projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc
          style={{
            marginBottom: "40px",
          }}
        >
          I have worked on a wide range of projects. From web apps to android
          apps. Here are some of my projects.
        </Desc>

        <CardContainer>
          {projects
            .filter((item) => toggle === "all" || item.category === toggle)
            .map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            ))}
        </CardContainer>

        {/* Button to redirect to the Projects page */}
        <Link to="/Projects">
          <StyledButton>
            <span className="svg-left">
              <svg
                className="w-5 h-5 text-green-400"
                fill="currentColor"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
            View More Projects
            <span className="svg-right">
              <svg
                className="w-5 h-5 text-green-400"
                fill="currentColor"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
          </StyledButton>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Projects1;

// Sample projects array
export const projects1 = [
  {
    id: 7,
    title: "SHE Web App",
    date: "Oct 2024",
    description:
      "A responsive web app focused on women's safety, featuring panic buttons and real-time alerts for emergencies.",
    image:
      pic1,
    tags: ["React", "CSS", "Node.js", "Responsive"],
    category: "web development",
    github: "https://github.com/brittytino/SHE-Web-App",
    webapp: "https://she-protect-her.vercel.app/",
    member: [
      {
        name: "Tino Britty",
        img: "https://avatars.githubusercontent.com/u/153193545?v=4",
        linkedin: "https://www.linkedin.com/in/brittytino/",
        github: "https://github.com/brittytino/",
      },
    ],
},
  {
    id: 11,
    title: "Ordusion",
    date: "Jul 2024",
    description:
      "An intercollege event registration platform that combines fun and functionality, utilizing Three.js for an engaging experience.",
    image:
      pic5,
    tags: ["HTML5", "CSS", "JavaScript", "Three.js"],
    category: "web development",
    github: "https://github.com/brittytino/Ordusion",
    webapp: "https://www.ordusion.tech/",
    member: [
      {
        name: "Tino Britty",
        img: "https://avatars.githubusercontent.com/u/153193545?v=4",
        linkedin: "https://www.linkedin.com/in/brittytino/",
        github: "https://github.com/brittytino/",
      },
    ],
},
{
  id: 8,
  title: "CyberSentinel",
  date: "Sep 2024",
  description:
    "A dashboard for Scanning Vulnerablities in OEM Software & Hardwares .",
  image:
    pic3,
  tags: ["React", "NextJS", "Tailwind", "Bootstrap"],
  category: "web development",
  github: "https://github.com/brittytino/Kovai-Circuit",
  webapp: "https://kovaicircuits.vercel.app/",
  member: [
    {
      name: "Tino Britty",
      img: "https://avatars.githubusercontent.com/u/153193545?v=4",
      linkedin: "https://www.linkedin.com/in/brittytino/",
      github: "https://github.com/brittytino/",
    },
  ],
},
];
