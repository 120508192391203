import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';
import styled from 'styled-components';

// Styled Components
const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

// Main Component
function LinkedinWidget() {
  return (
    <>
      <Title>LinkedIn</Title>
      <Desc style={{ marginBottom: "40px" }}>
        I have been active on LinkedIn with over 500K+ impressions, sharing my little stories, being consistent on LinkedIn, <br />and making new connections.
      </Desc>
      <ElfsightWidget widgetID="e7eb9e18-3096-4de6-b90d-484ba03e20a4" />
    </>
  );
}

export default LinkedinWidget;
